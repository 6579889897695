/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
q:before,
blockquote:after,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
textarea {
  overflow: auto;
}
a {
  text-decoration: none;
}
.clearfix {
  display: block;
  zoom: 1;
}
.clearfix:after {
  content: " ";
  display: block;
  font-size: 0;
  height: 0;
  clear: both;
  visibility: hidden;
}
body {
  background-color: #131C32;
  overflow-y: scroll;
}
a,
div,
p,
span {
  transition: opacity 350ms ease-out 50ms, color 350ms ease-out 50ms, background-color 350ms ease-out 50ms;
}
.hideFocus *:focus {
  outline: none!important;
}
.browser-fail {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f27210;
  z-index: 4;
}
.browser-fail p {
  padding: 5px 8%;
  line-height: 120%;
  text-align: center;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 300;
  color: #131c33;
}
.browser-fail #browserFail--close {
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  height: 12px;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  color: #131c33;
}
.browser-fail #browserFail--close:hover {
  color: #000;
}
@-webkit-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.round-spinner {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  -webkit-animation: spinner 1500ms linear infinite;
          animation: spinner 1500ms linear infinite;
}
.round-spinner .round-spinner__ball-1,
.round-spinner .round-spinner__ball-2,
.round-spinner .round-spinner__ball-3,
.round-spinner .round-spinner__ball-4,
.round-spinner .round-spinner__ball-5,
.round-spinner .round-spinner__ball-6,
.round-spinner .round-spinner__ball-7,
.round-spinner .round-spinner__ball-8 {
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #367cb8;
}
.round-spinner .round-spinner__ball-1 {
  opacity: 0;
  left: 75%;
  top: 25%;
  margin: -6px 0 0 -2px;
}
.round-spinner .round-spinner__ball-2 {
  opacity: 0.14;
  top: 50%;
  margin-top: -4px;
  right: 0;
}
.round-spinner .round-spinner__ball-3 {
  opacity: 0.29;
  left: 75%;
  top: 75%;
  margin: -2px 0 0 -2px;
}
.round-spinner .round-spinner__ball-4 {
  opacity: 0.43;
  bottom: 0;
  left: 50%;
  margin-left: -4px;
}
.round-spinner .round-spinner__ball-5 {
  opacity: 0.57;
  left: 25%;
  top: 75%;
  margin: -2px 0 0 -6px;
}
.round-spinner .round-spinner__ball-6 {
  opacity: 0.72;
  top: 50%;
  margin-top: -4px;
  left: 0;
}
.round-spinner .round-spinner__ball-7 {
  opacity: 0.86;
  left: 25%;
  top: 25%;
  margin: -6px 0 0 -6px;
}
.round-spinner .round-spinner__ball-8 {
  opacity: 1.0;
  top: 0;
  left: 50%;
  margin-left: -4px;
}
.add-indicator-modal .add-indicator-modal__sleeve {
  padding: 50px 50px 20px;
}
.add-indicator-modal .add-indicator-modal__padding {
  padding-right: 50px;
}
.add-indicator-modal .add-indicator-modal__title {
  font-size: 16px;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  color: #85a7d5;
  text-align: center;
  padding: 25px 20px;
}
.add-indicator-modal .add-indicator-modal__info {
  display: inline-block;
  margin-left: 20px;
  height: 14px;
  width: 14px;
}
.add-indicator-modal .add-indicator-modal__trend-box {
  position: relative;
  overflow-y: auto;
  padding-bottom: 20px;
}
.add-indicator-modal .add-indicator-modal__trend {
  position: relative;
  border-radius: 2px;
  padding: 10px 20px;
  margin: 5px 0 5px 30px;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  cursor: pointer;
}
.add-indicator-modal .add-indicator-modal__trend:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  z-index: 1;
  background-color: #000;
  opacity: 0;
  border-radius: 2px;
  transition: opacity 350ms ease-out 50ms;
}
.add-indicator-modal .add-indicator-modal__trend:hover:after {
  opacity: 0.1;
}
.add-indicator-modal .add-indicator-modal__trend.selected .add-indicator-modal__trend--checkbox:after {
  opacity: 1;
}
.add-indicator-modal .add-indicator-modal__trend.editing {
  margin-left: 0;
}
.add-indicator-modal .add-indicator-modal__trend.editing .add-indicator-modal__trend--checkbox {
  opacity: 0;
}
.add-indicator-modal .add-indicator-modal__trend--text {
  color: #1f273d;
}
.add-indicator-modal .add-indicator-modal__trend--checkbox {
  position: absolute;
  left: -25px;
  top: 50%;
  margin-top: -7px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: #131C32;
  cursor: pointer;
}
.add-indicator-modal .add-indicator-modal__trend--checkbox:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMjUuMjQgMTkxLjY4Ij4KICA8cGF0aCBjbGFzcz0iY2hlY2siIGZpbGw9IiM0YWE3ZjkiIGQ9Ik0zNzksMTc1YzMsMyw0LDUsMSw5LTEuNTYsMi4wOC0xMDYuMTUsMTMwLjM1LTEyMywxNTEtMS40NywxLjgtNCwyLTcsMHMtODkuNzgtNzMuMDYtOTEuMDktNzQuMzNjLTMuMzQtMy4yNC0yLjkxLTQuNjcuMDktOC42Nyw4LjExLTEwLjgxLDIxLjU4LTI4LDIzLjQyLTMwLjI3LDIuNzMtMy4zNSw0LTMuNzEsNy4xMy0uODcsMy4zMywzLDQ5Ljk1LDQyLjY0LDQ5Ljk1LDQyLjY0czk0Ljc3LTExMi4xNSw5Ny42NC0xMTUuODJTMzQyLDE0NCwzNDUuNSwxNDYuNSwzNzcsMTczLDM3OSwxNzVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTU2LjU3IC0xNDQuNzUpIi8+Cjwvc3ZnPgo= );
  background-repeat: no-repeat;
  opacity: 0;
}
.add-indicator-modal .add-indicator-modal__trend--error {
  position: absolute;
  width: 100%;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: #ef8700;
}
.add-indicator-modal .add-indicator-modal__ma-days {
  height: 0;
  overflow: hidden;
}
.add-indicator-modal .add-indicator-modal__ma-days.open {
  margin: 15px 0 20px 35px;
  height: auto;
}
.add-indicator-modal .add-indicator-modal__ma-days--text {
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 300;
  color: #85a7d5;
}
.add-indicator-modal .add-indicator-modal__buttons {
  padding-bottom: 50px;
  text-align: center;
}
.add-indicator-modal .add-indicator-modal__confirm-btn {
  height: 44px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #1cc2ff;
  border: solid 1px #1cc2ff;
  text-align: center;
  transition: opacity 350ms ease-out 50ms, background-color 350ms ease-out 50ms, border-color 350ms ease-out 50ms, color 350ms ease-out 50ms;
  display: inline-block;
  width: 120px;
  margin: 0 10px;
}
.add-indicator-modal .add-indicator-modal__confirm-btn:hover {
  background-color: #0791c4;
  border-color: #0791c4;
}
.add-indicator-modal .add-indicator-modal__confirm-btn:hover p {
  color: #131C32;
}
.add-indicator-modal .add-indicator-modal__confirm-btn:active {
  transform: translate3d(0, 1px, 0);
}
.add-indicator-modal .add-indicator-modal__confirm-btn--text {
  padding: 12px 0;
  font-size: 16px;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  text-transform: lowercase;
  font-variant: small-caps;
  letter-spacing: 0.0625em;
  color: #1f273d;
  font-size: 18px;
}
.add-indicator-modal .add-indicator-modal__confirm-btn--text:hover {
  color: #131C32;
}
.add-indicator-modal .add-indicator-modal__copy-btn {
  position: relative;
  height: 44px;
  border-radius: 2px;
  cursor: pointer;
  border: solid 1px #1cc2ff;
  text-align: center;
  transition: opacity 350ms ease-out 50ms, background-color 350ms ease-out 50ms, border-color 350ms ease-out 50ms, color 350ms ease-out 50ms;
  display: inline-block;
  width: 120px;
  margin: 0 10px;
}
.add-indicator-modal .add-indicator-modal__copy-btn:hover {
  border: solid 1px #0791c4;
}
.add-indicator-modal .add-indicator-modal__copy-btn:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #000;
  opacity: 0;
  transition: opacity 350ms ease-out 50ms;
}
.add-indicator-modal .add-indicator-modal__copy-btn:hover:after {
  opacity: 0.1;
}
.add-indicator-modal .add-indicator-modal__copy-btn:active {
  transform: translate3d(0, 1px, 0);
}
.add-indicator-modal .add-indicator-modal__copy-btn--text {
  padding: 12px 0;
  font-size: 16px;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  text-transform: lowercase;
  font-variant: small-caps;
  letter-spacing: 0.0625em;
  color: #1cc2ff;
  font-size: 18px;
}
.add-indicator-modal .add-indicator-modal__copy-btn--text:hover {
  color: #0791c4;
}
.add-indicator-modal .checkbox.dark .checkbox-option__btn {
  background-color: #131C32;
}
.add-indicator-modal .checkbox-option .input input {
  padding: 3px;
  margin: -3px;
  color: #85a7d5;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  letter-spacing: 0.0625em;
  font-size: 12px;
  border-radius: 2px;
}
.add-indicator-modal .checkbox-option .input input:focus {
  border: 1px solid transparent;
  box-shadow: none;
}
.add-indicator-modal .checkbox-option input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #85a7d5;
}
.add-indicator-modal .checkbox-option input::-moz-placeholder {
  /* Firefox 19+ */
  color: #85a7d5;
}
.add-indicator-modal .checkbox-option input:-ms-input-placeholder {
  /* IE 10+ */
  color: #85a7d5;
}
.add-indicator-modal .checkbox-option input:-moz-placeholder {
  /* Firefox 18- */
  color: #85a7d5;
}
.add-indicator-modal .checkbox.radio .checkbox-option__btn.active:after {
  background-color: #0fa8e0;
}
.lightUI .add-indicator-modal .add-indicator-modal__title {
  color: #465777;
}
.lightUI .add-indicator-modal .add-indicator-modal__trend--text {
  color: #fff;
}
.lightUI .add-indicator-modal .add-indicator-modal__ma-days--text {
  color: #465777;
}
.lightUI .add-indicator-modal .add-indicator-modal__trend--checkbox {
  background-color: #d5deea;
}
.lightUI .add-indicator-modal .checkbox-option .input input {
  padding: 3px;
  margin: -3px;
  color: #203056;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  letter-spacing: 0.0625em;
  font-size: 12px;
  border-radius: 0;
}
.lightUI .add-indicator-modal .checkbox-option .input input:focus {
  background-color: #d5deea;
}
.lightUI .add-indicator-modal .checkbox-option input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #203056;
}
.lightUI .add-indicator-modal .checkbox-option input::-moz-placeholder {
  /* Firefox 19+ */
  color: #203056;
}
.lightUI .add-indicator-modal .checkbox-option input:-ms-input-placeholder {
  /* IE 10+ */
  color: #203056;
}
.lightUI .add-indicator-modal .checkbox-option input:-moz-placeholder {
  /* Firefox 18- */
  color: #203056;
}
.lightUI .add-indicator-modal .add-indicator-modal__copy-btn {
  border: solid 1px #88c400;
}
.lightUI .add-indicator-modal .add-indicator-modal__copy-btn:hover {
  border: solid 1px #76ab00;
}
.lightUI .add-indicator-modal .add-indicator-modal__copy-btn--text {
  color: #88c400;
}
.lightUI .add-indicator-modal .add-indicator-modal__copy-btn--text:hover {
  color: #76ab00;
}
.graph .graph__title input {
  margin-top: 30px;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 200;
  font-size: 28px;
  letter-spacing: 0.02em;
  line-height: 120%;
  text-align: center;
  color: #ededed;
  cursor: pointer;
}
.graph .graph__box {
  position: relative;
  height: calc( 100vh - 200px);
  min-height: 400px;
  background-color: #131C32;
  border-radius: 6px;
}
.graph .graph__box--ghost {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: 0;
  border-radius: 6px;
  background: #1f273d;
}
.graph .graph__legend {
  position: relative;
  width: calc( 100% - 180px );
  margin: 0 auto;
  text-align: left;
  z-index: 3;
}
.graph .graph__legend.lower {
  z-index: 2;
}
.graph .graph__legend.elevate {
  z-index: 5;
}
.graph .graph__control-box {
  width: calc( 100% - 180px );
  margin: 0 auto;
}
.graph .graph__content-filter-indicator--box {
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 90px;
  left: 90px;
  z-index: 2;
}
.graph .graph__content-filter-indicator {
  position: absolute;
  top: 0;
  bottom: -7px;
  margin-left: 1px;
  background-color: #1cc2ff;
  opacity: 0;
  transition: none;
}
.graph .graph__content-filter-indicator.visible {
  opacity: 0.8;
}
.graph .graph__content-filter-indicator__close {
  position: absolute;
  width: 15px;
  height: 22px;
  bottom: -42px;
  left: 50%;
  margin-left: -8px;
  cursor: pointer;
}
.graph .graph__content-filter-indicator__close svg {
  transform: rotate(180deg);
}
.graph .graph__content-filter-indicator__close:hover {
  opacity: 0.8;
}
.graph .graph__drag-zoom-indicator {
  position: absolute;
  top: 2px;
  bottom: 3px;
  margin-left: 1px;
  background-color: #d5deea;
  opacity: 0.3;
  pointer-events: none;
  z-index: 1;
}
.graph .graph__drag-zoom-indicator.clear {
  opacity: 0;
}
.graph .graph__timeTrackerMask {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}
.graph .graph__timeTrackerBox {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.graph .graph__time-tracker {
  position: absolute;
  top: -2px;
  margin-left: 1px;
  bottom: 0;
  opacity: 0;
  transition: opacity 350ms ease-out 50ms;
}
.graph .graph__time-tracker:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0fa8e0;
  opacity: 0.1;
}
.graph .graph__time-tracker:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #0fa8e0;
  border-style: none solid;
}
.graph .graph__time-tracker.active {
  opacity: 1;
}
.graph .graph__grid-box {
  position: relative;
  width: 100%;
  height: calc( 100vh - 470px );
  margin-top: 45px;
}
.graph .graph__timeseries-wrap--area.fade svg,
.graph .graph__timeseries-wrap.fade svg {
  opacity: 0.1;
}
.graph .graph__spinner {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 50px;
  height: 50px;
  opacity: 0;
  z-index: 2;
}
.graph .graph__spinner.visible {
  opacity: 1;
}
.graph .graph__info-btn {
  position: absolute;
  bottom: 17px;
  padding-bottom: 2px;
  z-index: 1;
  width: 60px;
  left: 50%;
  margin-left: -30px;
  font-weight: 300;
  font-size: 16px;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  text-transform: lowercase;
  font-variant: small-caps;
  letter-spacing: 0.0625em;
  text-align: center;
  color: #657694;
  cursor: default;
  vertical-align: middle;
  border-bottom: 1px dotted #657694;
  transition: none;
}
.graph .graph__info-btn:focus {
  border-bottom: 1px dotted #85a7d5;
  color: #85a7d5;
  outline: none;
}
.graph .graph__info-btn:focus svg path {
  fill: #85a7d5 !important;
}
.graph.loading .graph__legend,
.graph.loading .graph-legend--display {
  opacity: 0.3;
}
.graph.loading .graph__legend:after,
.graph.loading .graph-legend--display:after {
  opacity: 0;
}
.lightUI .graph .graph__title input {
  color: #465777;
}
.lightUI .graph .graph__box {
  background-color: #fff;
}
.lightUI .graph .graph__box--ghost {
  opacity: 0;
}
.graph-legend__x-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
}
.graph-legend__x {
  position: absolute;
  width: 1px;
  height: 100%;
  right: 0;
  margin-right: -1px;
  top: 2px;
  background-color: #2a3851;
  transition: background-color 750ms ease-out 50ms, color 750ms ease-out 50ms;
}
.graph-legend__x:first-of-type,
.graph-legend__x:last-of-type {
  background-color: #465777;
  z-index: 1;
  width: 2px;
  margin-right: 0;
}
.graph-legend__x:last-of-type {
  right: 0;
  left: auto!important;
}
.graph-legend__x--animators {
  position: absolute;
  width: 1px;
  height: 100%;
  right: 0;
  margin-right: -1px;
  top: 2px;
  background-color: #2a3851;
  transition: background-color 750ms ease-out 50ms, color 750ms ease-out 50ms;
  opacity: 0;
}
.graph-crosshairs .graph-crosshair__target {
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 1px;
  transition: none;
  opacity: 0;
}
.graph-crosshairs .graph-crosshairs__y {
  position: absolute;
  height: 11px;
  left: -1px;
  right: 0;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
.graph-crosshairs .graph-crosshairs__y--line {
  position: absolute;
  width: 5px;
  top: 5px;
  height: 1px;
  left: -5px;
  background-color: #23C7B4;
}
.graph-crosshairs .graph-crosshairs__y--label {
  position: absolute;
  right: -75px;
  top: -1px;
  height: 14px;
  width: 70px;
  padding: 2px;
  font-size: 10px;
  border-radius: 2px;
  text-align: center;
  color: #131C32;
  background-color: #23C7B4;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  opacity: 0;
}
.graph-crosshairs .graph-crosshairs__y--label--caret {
  position: absolute;
  left: -4px;
  top: 4px;
  height: 0;
  width: 0;
  border-right: solid 5px #23C7B4;
  border-top: solid 3px transparent;
  border-bottom: solid 3px transparent;
}
.graph-crosshairs .graph-crosshairs__y--dot {
  position: absolute;
  width: 5px;
  height: 5px;
  margin-left: 0px;
  top: 3px;
  border: 1px solid #23C7B4;
  background-color: #131C32;
  border-radius: 50%;
}
.graph-crosshairs .graph-crosshairs__y--dot:after {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  border-radius: 50%;
  opacity: 0.2;
  background-color: #23C7B4;
}
.graph-crosshairs.visible .graph-crosshairs__y {
  opacity: 1;
}
.graph-crosshairs.drag .graph-crosshairs__y {
  opacity: 0;
}
.graph-crosshairs.showLabel .graph-crosshairs__y--label {
  opacity: 1;
}
.lightUI .graph-crosshairs .graph-crosshairs__y--line {
  background-color: #1ab2a0;
}
.lightUI .graph-crosshairs .graph-crosshairs__y--label {
  background-color: #1ab2a0;
}
.lightUI .graph-crosshairs .graph-crosshairs__y--label--caret {
  border-right: solid 5px #1ab2a0;
}
.lightUI .graph-crosshairs .graph-crosshairs__y--dot {
  border: 1px solid #1ab2a0;
  background-color: #fff;
}
.lightUI .graph-crosshairs .graph-crosshairs__y--dot:after {
  content: '';
  background-color: #1ab2a0;
}
.lightUI .graph-crosshairs .graph-crosshairs__x {
  background-color: #1ab2a0;
}
.lightUI .graph-crosshairs .graph-crosshairs__x--date {
  color: #1ab2a0;
}
.graph-grid {
  position: relative;
  width: 100%;
  height: 100%;
}
.graph-grid .graph-grid__x-box,
.graph-grid .graph-grid__y-box {
  position: absolute;
  width: 100%;
  height: 100%;
}
.graph-grid .graph-grid__x {
  position: absolute;
  width: 1px;
  height: 100%;
  right: 0;
  margin-right: -1px;
  background-color: #2a3851;
  transition: background-color 750ms ease-out 50ms, color 750ms ease-out 50ms;
}
.graph-grid .graph-grid__x--animators {
  position: absolute;
  width: 1px;
  height: 100%;
  right: 0;
  margin-right: -1px;
  background-color: #2a3851;
  opacity: 0;
  transition: background-color 750ms ease-out 50ms, color 750ms ease-out 50ms;
}
.graph-grid .graph-grid__y:first-of-type,
.graph-grid .graph-grid__x:first-of-type,
.graph-grid .graph-grid__x:last-of-type {
  background-color: #465777;
  z-index: 1;
}
.graph-grid .graph-grid__x:first-of-type,
.graph-grid .graph-grid__x:last-of-type {
  width: 2px;
  margin-right: 0;
}
.graph-grid .graph-grid__x:last-of-type {
  right: 0;
  left: auto!important;
}
.graph-grid .graph-grid__y {
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  background-color: #2a3851;
  transition: opacity 750ms ease-out 50ms, background-color 750ms ease-out 50ms, color 750ms ease-out 50ms;
}
.graph-grid.animating .graph-grid__y {
  transition: opacity 750ms ease-out 50ms, background-color 750ms ease-out 50ms, color 750ms ease-out 50ms, transform 750ms ease-out 50ms;
}
.graph-grid.animatingX .graph-grid__x {
  opacity: 0;
  visibility: none;
  transition: background-color 750ms ease-out 50ms, color 750ms ease-out 50ms, transform 750ms ease-out 50ms;
}
.graph-grid.animatingX .graph-grid__x--animators {
  opacity: 1;
  visibility: visible;
  transition: background-color 750ms ease-out 50ms, transform 750ms ease-out 50ms, color 750ms ease-out 50ms;
}
.lightUI .graph-grid .graph-grid__y,
.lightUI .graph-grid .graph-grid__x,
.lightUI .graph-grid .graph-grid__x--animators {
  background-color: #d5deea;
}
.lightUI .graph-grid .graph-grid__y:first-of-type,
.lightUI .graph-grid .graph-grid__x:first-of-type {
  background-color: #a7b7cc;
}
.graph-indicator__dot {
  position: absolute;
  box-shadow: 0 5px 10px -1px #000000;
  height: 9px;
  width: 9px;
  margin: -8px 0 0 -4px;
}
.graph-indicator__dot.fade {
  opacity: 0.1;
}
.graph-indicator__dot.fade {
  opacity: 0.1;
}
.graph-indicator--tooltip__dot {
  position: absolute;
  height: 15px;
  width: 15px;
  margin: -7px 0 0 -7px;
  border-radius: 8px;
  border: 6px solid #23C7B4;
  background-color: #ededed;
  cursor: pointer;
  opacity: 0.3;
  overflow: hidden;
  z-index: 1;
  transform: translate3d(0, 0, 0);
  transition: max-height 350ms ease-out 50ms, min-height 350ms ease-out 50ms, width 350ms ease-out 50ms, margin 350ms ease-out 50ms;
  box-shadow: 0 3px 15px -1px #000000;
}
.graph-indicator--tooltip__dot:hover {
  height: auto;
  max-height: 400px;
  min-height: 30px;
  width: 180px;
  margin-left: -90px;
  background-color: #354663;
  opacity: 1;
  z-index: 2;
  border-width: 0px;
  border-radius: 2px;
  transform: translate3d(0, -65px, 0);
}
.graph-indicator--tooltip__dot:hover .graph-indicator--tooltip__dot--text-box {
  opacity: 1;
  transition: opacity 100ms ease-out 350ms;
}
.graph-indicator--tooltip__dot .graph-indicator--tooltip__dot--text-box {
  position: relative;
  width: 180px;
  margin: auto;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  color: #85a7d5;
  background-color: #354663;
  text-align: center;
  opacity: 0;
  transition: opacity 100ms ease-out 0ms;
}
.graph-indicator--tooltip__dot .graph-indicator--tooltip__dot--date {
  font-size: 11px;
  color: #accaf2;
  text-align: right;
  margin-right: 10px;
  padding-bottom: 5px;
}
.graph-indicator--tooltip__dot .graph-indicator--tooltip__dot--bluf {
  text-align: left;
  margin-top: 5px;
  padding: 5px 10px;
}
.graph-indicator--tooltip__dot .graph-indicator--tooltip__dot--tag-box {
  position: absolute;
  bottom: 6px;
  left: 8px;
  text-align: left;
}
.graph-indicator--tooltip__dot .graph-indicator--tooltip__dot--tag {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 0 4px;
  border-radius: 2px;
}
.graph-indicator--tooltip__dot.fade {
  opacity: 0.1;
}
.animating .graph-indicator--tooltip__dot,
.animating .graph-indicator__dot {
  display: none;
}
.lightUI .graph-indicator__dot {
  box-shadow: none;
}
.lightUI .graph-indicator--tooltip__dot {
  background-color: #fff;
  box-shadow: none;
}
.lightUI .graph-indicator--tooltip__dot:hover {
  box-shadow: 0 3px 15px -1px rgba(0, 0, 0, 0.5);
}
.graph-timeseries-area,
.graph-timeseries {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1px;
  right: 0;
  z-index: 1;
}
.graph-timeseries-area .graph-timeseries__sleeve,
.graph-timeseries .graph-timeseries__sleeve {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 750ms ease-out 50ms;
}
.graph-timeseries-area .graph-timeseries__svg,
.graph-timeseries .graph-timeseries__svg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale3d(1, 1, 1);
  transition: opacity 350ms ease-out 50ms;
  transform-origin: center bottom;
}
@supports (-ms-accelerator:true) {
  .graph-timeseries-area .graph-timeseries__line,
  .graph-timeseries .graph-timeseries__line {
    stroke-width: 0.5px;
  }
}
@supports (-ms-ime-align:auto) {
  .graph-timeseries-area .graph-timeseries__line,
  .graph-timeseries .graph-timeseries__line {
    stroke-width: 0.5px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .graph-timeseries-area .graph-timeseries__line,
  .graph-timeseries .graph-timeseries__line {
    stroke-width: 0.5px;
  }
}
.graph-timeseries-area {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
.graph-timeseries.animating .graph-timeseries__svg,
.graph-timeseries-area.animating .graph-timeseries__svg {
  transition: transform 750ms linear 50ms, opacity 350ms ease-out 50ms;
}
.graph-timeseries.animatingX,
.graph-timeseries-area.animatingX {
  overflow: hidden;
}
.graph-timeseries.animatingX .graph-timeseries__svg,
.graph-timeseries-area.animatingX .graph-timeseries__svg {
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center right;
  transition: transform 750ms ease-out 50ms, opacity 350ms ease-out 50ms;
}
.graph-timeseries.animatingCustom,
.graph-timeseries-area.animatingCustom {
  overflow: hidden;
}
.graph-timeseries.animatingCustom .graph-timeseries__sleeve,
.graph-timeseries-area.animatingCustom .graph-timeseries__sleeve {
  transition: transform 750ms ease-in 50ms;
}
.graph-timeseries.animatingCustomBounce,
.graph-timeseries-area.animatingCustomBounce {
  overflow: hidden;
}
.graph-timeseries.animatingCustomBounce .graph-timeseries__svg,
.graph-timeseries-area.animatingCustomBounce .graph-timeseries__svg {
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center bottom;
  transition: transform 750ms ease-out 50ms, opacity 350ms ease-out 50ms;
}
.graph-timeseries.animatingResetBaseline .graph-timeseries__svg,
.graph-timeseries-area.animatingResetBaseline .graph-timeseries__svg {
  opacity: 0;
  transform: scale3d(1, 0, 1) !important;
  transform-origin: center bottom;
  transition: transform 750ms ease-out 50ms, opacity 750ms ease-out 50ms;
}
.graph-timeseries.entering .graph-timeseries__svg,
.graph-timeseries-area.entering .graph-timeseries__svg {
  opacity: 0;
}
.graph-timeseries.indicator {
  z-index: 2;
}
.graph-timeseries.translucent {
  opacity: 0.6;
}
.graph-timeseries.dim {
  opacity: 0.1;
}
.graph-xaxis {
  position: absolute;
  bottom: 2px;
  left: 90px;
  width: calc( 100% - 180px );
}
.graph-xaxis .graph-xaxis__point {
  position: absolute;
  width: 1px;
  height: 5px;
  top: 0;
  margin-right: -1px;
  background-color: #465777;
  transition: opacity 350ms ease-out 50ms;
}
.graph-xaxis .graph-xaxis__point.entering {
  opacity: 0;
}
.graph-xaxis .graph-xaxis__point:first-of-type {
  width: 2px;
  margin-right: 0;
}
.graph-xaxis .graph-xaxis__point--date {
  position: absolute;
  width: 100px;
  left: -50px;
  top: 11px;
  text-align: center;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #accaf2;
}
.graph-xaxis.animating .graph-xaxis__point {
  opacity: 0;
  transition: none;
}
.lightUI .graph-xaxis .graph-xaxis__point {
  background-color: #a7b7cc;
}
.graph-yaxis {
  position: absolute;
  width: 90px;
  top: -20px;
  bottom: -5px;
  overflow: hidden;
  z-index: 2;
}
.graph-yaxis .graph-yaxis__sleeve {
  position: absolute;
  top: 0;
  bottom: 7px;
  left: 0;
  right: 0;
}
.graph-yaxis .graph-yaxis__info-btn__icon {
  display: inline-block;
  height: 13px;
  width: 13px;
  margin-left: -14px;
  margin-bottom: 2px;
  vertical-align: middle;
  pointer-events: none;
}
.graph-yaxis .graph-yaxis__point {
  position: absolute;
  height: 1px;
  width: 5px;
  right: 0;
  bottom: 0;
  background-color: #465777;
  transition: opacity 750ms ease-out 50ms, background-color 750ms ease-out 50ms, color 750ms ease-out 50ms;
}
.graph-yaxis .graph-yaxis__point.entering {
  opacity: 0;
}
.graph-yaxis .graph-yaxis__point--label {
  position: absolute;
  width: 60px;
  right: 11px;
  top: -6px;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #c0d9f9;
  text-align: right;
}
.graph-yaxis.animating .graph-yaxis__point {
  transition: opacity 750ms ease-out 50ms, background-color 750ms ease-out 50ms, color 750ms ease-out 50ms, transform 750ms ease-out 50ms;
}
.lightUI .graph-yaxis .graph-yaxis__point {
  background-color: #a7b7cc;
}
.compare .graph-yaxis .graph-yaxis__sleeve {
  bottom: 5px;
}
.graphZoom .graphZoom__custom--begin,
.graphZoom .graphZoom__custom--end {
  padding-bottom: 3px;
  border-bottom: dotted 1px #0fa8e0;
}
.graphZoom .graphZoom__custom--begin:hover,
.graphZoom .graphZoom__custom--end:hover {
  color: #accaf2;
}
.graphZoom .graphZoom__custom--spacer {
  margin: 0 18px;
}
.graphZoom .graphZoom__custom-dropdown {
  position: relative;
  float: left;
  margin: 0 15px 9px 0;
  z-index: 1;
}
.graphZoom .graphZoom__datepicker {
  float: left;
  width: 300px;
  margin-top: 1px;
}
.graphZoom .graphZoom__datepicker__inputBox {
  display: inline-block;
  position: relative;
  width: calc( 100% - 35px );
}
.graphZoom .graphZoom__datepicker__start {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 46%;
}
.graphZoom .graphZoom__datepicker__start > div {
  height: 100%;
}
.graphZoom .graphZoom__datepicker__end {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 46%;
}
.graphZoom .graphZoom__datepicker__end > div {
  height: 100%;
}
.graphZoom .graphZoom__datepicker__to {
  display: inline-block;
  width: 8%;
  text-align: center;
  font-size: 16px;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  text-transform: lowercase;
  font-variant: small-caps;
  letter-spacing: 0.0625em;
  color: #7c8faf;
  font-size: 10px;
}
.graphZoom .graphZoom__datepicker__btn {
  float: right;
  width: 30px;
  height: 24px;
  margin: 3px -1px 0 0;
  border: 1px solid #465777;
  border-radius: 3px;
  text-align: center;
  cursor: default;
  transition: background-color 350ms ease-out 50ms, color 350ms ease-out 50ms, border-color 350ms ease-out 50ms;
}
.graphZoom .graphZoom__datepicker__btn-text {
  font-size: 16px;
  font-family: 'Montserrat', Verdana, Geneva, sans-serif;
  font-weight: 400;
  text-transform: lowercase;
  font-variant: small-caps;
  letter-spacing: 0.0625em;
  color: #465777;
  line-height: 140%;
  padding: 3px 0;
  font-size: 12px;
}
.graphZoom .graphZoom__datepicker__btn.active {
  background-color: #1cc2ff;
  border-color: #1cc2ff;
  cursor: pointer;
}
.graphZoom .graphZoom__datepicker__btn.active .graphZoom__datepicker__btn-text {
  color: #fff;
}
.graphZoom .graphZoom__datepicker__btn.active:hover {
  background-color: #0fa8e0;
  border-color: #0fa8e0;
}
.graphZoom .graphZoom__datepicker__btn.active:hover .graphZoom__datepicker__btn-text {
  color: #ededed;
}
.lightUI .graphZoom .graphZoom__datepicker__btn {
  border: 1px solid #dedede;
}
.lightUI .graphZoom .graphZoom__datepicker__btn-text {
  color: #dedede;
}
.lightUI .graphZoom .graphZoom__datepicker__btn.active {
  background-color: #0fa8e0;
  border-color: #0fa8e0;
}
.lightUI .graphZoom .graphZoom__datepicker__btn.active:hover {
  background-color: #0791c4;
  border-color: #0791c4;
}
